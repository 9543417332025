.head-tab{
    display: flex;
}
.head-tab li{
    list-style: none;
    /* border:1px solid black; */
    min-width: 60px;
    height: 20px;
    color: #777777;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    margin-right: 20px;
    text-align: center;
}
.head-tab-active{
    color: #65b7ff!important;
    border-bottom: 3px solid #65b7ff;
}

.preview-card-big{
    width: 49%;
    height: 420px;
    /* border:1px solid black; */
}
.preview-card-small{
    width: 49%;
    height: 130px;
    /* border:1px solid black; */
}
.overlay{
    width: 100%;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 15px 0 rgba(37, 37, 37, 0.16);

    /* display: inline-block; */
}
.overlay:after{
    content:'';
    position:absolute;
    left:0; top:0;bottom:0;
    width:100%; 
    height: 100%;
    /* display:inline-block; */
    background-image:linear-gradient(to right, rgba(0, 161, 237, 0.2), rgba(225, 183, 200, 0.4));
}
.img-box-big{
    width: 100%;
    height: 420px;
    display: block;
    object-fit: cover;
}
.overlay .caption{
    position: absolute;
    width: 350px!important;
    height: 260px;
    bottom: 0px;
    left:30px!important;
    top:90px;
    /* margin:auto; */
    background-color: white;
    z-index: 1;
}
.caption{
    padding:20px
}
.caption h3{
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: rgba(0, 161, 237, 0.4);
}
.caption h1{
    font-size: 25px;
    font-weight: 600;
    color: #464646;
}
.caption p{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    /* font-weight: bold; */
    color: #777777;
}

.overlay-small{
    position: relative;
    display: flex;
    justify-content: space-between;
    /* border:1px solid black; */
    height: 125px;
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(37, 37, 37, 0.16);
    /* display: inline-block; */
}
.overlay-small:after{
    content:'';
    position:absolute;
    left:0; top:0;
    width:35%; 
    height: 100%;
    /* display:inline-block; */
    background-image:linear-gradient(to right, rgba(0, 161, 237, 0.2), rgba(225, 183, 200, 0.4));
}
.type-event{
    position: absolute;
    margin: auto;
    width:40%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.type-event p{
    color:#fff;
    font-size: 12px;
    font-weight: bold;
}

.card{
    width:60%;
    padding-top: 10px;
    padding-right: 10px;

    justify-content: space-around;

}
.card h6{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 161, 237, 0.4);
    margin-bottom: 10px;
    margin-top: 0px;
}
.card h3{
    font-size: 17px;
    font-weight: bold;
    color: #464646;
    margin-bottom: 5px;
    /* border:1px solid red!important; */
    width:260px;
    overflow: hidden;
    /* white-space: nowrap;         */
    /* text-overflow: ellipsis; */
}
.img-box-small{
    width: 35%;
    min-height: 100%;
    display: block;
    object-fit: cover;
}


.detail-overlay{
    position: relative;
    width: 100%;
    height: 200px;
}
.detail-overlay:after{
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
    background-image: linear-gradient(to right, rgba(0, 161, 237, 0.7), rgba(225, 183, 200, 0.7));
}
.detail-overlay img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.detail-caption{
    position: absolute;
    width: 620px;
    height: 100px;
    bottom: 0px;
    left:50px;
    top:70px;
    /* border:1px solid black; */
    /* margin:auto; */
    /* background-color: white; */
    z-index: 1;
}
.detail-caption{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.detail-caption p{
    color:white;
    font-family: 'Lato', sans-serif;
    font-size: 10px;
}

.detail-caption h1{
    color: white;
    font-size: 30px;
    font-family: 'Ibarra Real Nova', serif;

}

.content-insight{
    width: 82%;
    margin:auto;
   
}
.content-sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.content-sub p{
    color:rgba(0, 161, 237, 0.4);
    font-family: 'Lato', sans-serif;
}
.content-sub h6{
    color:#777777;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}
.content-detail p{
    color:#777777;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
}
.content-detail h3{
    color:#777777;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
}
.content-detail ol{
    margin-left: 15px;
}
.content-detail figure{
    text-align: center;
    width: 100%!important;
}
.content-detail figure img{
    width: 60%;
}
.filter-modal{
    padding:10px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    background-color: white;
    left: -30px;
    top: 30px;
    z-index: 2;

}
.sort-modal{
    padding:10px;
    width: 190px;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: absolute;
    background-color: white;
    left: -30px;
    top: 30px;
    z-index: 2;
}